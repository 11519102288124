import React from 'react'
import Icon from './icons'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import styles from './team-menu.module.css'

gsap.registerPlugin(ScrollToPlugin);

class teamMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const query = window.location.search.substring(1);
    // console.log(query);
    const button = document.querySelector(`[data-team='${query}']`);
    // console.log(button);
    if (button) {
      this.filterTeam(button, query);
    }
  }

  filterTeam(e, requestedTeam) {
    // Set current filter
    const element = 'currentTarget' in e ? e.currentTarget : e;
    const list = element.parentNode;
    const currentItem = list.querySelector('.current');
    if (currentItem) {
      currentItem.classList.remove(styles.current);
      currentItem.classList.remove('current');
    }

    element.classList.add(styles.current);
    element.classList.add('current');

    // Browser history
    window.history.pushState(null, null, `?${requestedTeam}`);

    // Get team members
    const teamMembersWrap = document.querySelector(`[data-filter-teams]`);
    const teamMembers = teamMembersWrap.querySelectorAll('[data-teams]');
    const displayContainer = document.querySelector(`[data-filtered-teams]`);
    gsap.to(window, { duration: 1, scrollTo: {y: "#profiles", offsetY: 50}, ease: "power4.inOut" });
    gsap.fromTo('[data-filtered-teams]', { opacity: 1 }, { duration: 0.3, opacity: 0, onComplete: () => {
      displayContainer.innerHTML = '';
      for (let t = 0; t < teamMembers.length; t++) {
        const card = teamMembers[t];
        const teams = card.dataset.teams;

        if (teams.indexOf(requestedTeam) > -1) {
            const clone = card.cloneNode(true);
            displayContainer.appendChild(clone);
        }
      }

      gsap.fromTo('[data-filtered-teams]', { opacity: 0 }, { duration: 0.3, opacity: 1 });
    }});
}

  render() {
    const searchTeam = event => {
        const query = event.target.value;
        const groupContainer = document.querySelector('[data-group-container]');
        if (query !== '') {
          groupContainer.classList.add(styles.hide);
          const teamMembersWrap = document.querySelector(`[data-filter-teams]`);
          const displayContainer = document.querySelector(`[data-filtered-teams]`);
          displayContainer.innerHTML = '';
          let count = 0;
          
          this.props.team.filter(member => {
              const name = member.name;

              if (name.toLowerCase().includes(query.toLowerCase()) && count < 8) {
                  const foundMember = teamMembersWrap.querySelector(`[data-member="${name}"]`);
                  const clone = foundMember.cloneNode(true);
                  displayContainer.appendChild(clone);
                  count++;
              }
          })
        } else {
          groupContainer.classList.remove(styles.hide);
        }
    }

    return (
        <div className={styles.teamMenu}>
          <div className={styles.searchBox}>
            <div>
              <input type="text" placeholder="I'm meeting with" onChange={searchTeam} />
              <Icon symbol='search' />
            </div>
          </div>
          <div className={styles.teamGroups} data-group-container>
            <div className={styles.grid}>
              <a onClick={(e) => this.filterTeam(e, 'early')} data-team='early' className={`${this.props.current === 'early' ? `${styles.current} current` : ''} ${styles.early}`}>
                <span>Early <Icon symbol='chevronright' /></span>
              </a>
              <a className={styles.blank}>&nbsp;</a>
              <a onClick={(e) => this.filterTeam(e, 'founders')}  data-team='founders'className={`${this.props.current === 'founders' ? `${styles.current} current` : ''} ${styles.founders}`}>
                <span>Founders + Emeritus <Icon symbol='chevronright' /></span>
              </a>
              <a onClick={(e) => this.filterTeam(e, 'founder-experience')} data-team='founder-experience' className={`${this.props.current === 'founder-experience' ? `${styles.current} current` : ''} ${styles.founderExperience}`}>
                <span>Founder Experience <Icon symbol='chevronright' /></span>
              </a>
              <a onClick={(e) => this.filterTeam(e, 'operations')} data-team='operations' className={`${this.props.current === 'operations' ? `${styles.current} current` : ''} ${styles.operations}`}>
                <span>Operations <Icon symbol='chevronright' /></span>
              </a>
              <a onClick={(e) => this.filterTeam(e, 'growth')} data-team='growth' className={`${this.props.current === 'growth' ? `${styles.current} current` : ''} ${styles.growth}`}>
                <span>Growth <Icon symbol='chevronright' /></span>
              </a>
            </div>
          </div>
        </div>
    )
  }
}

export default teamMenu