import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import Icon from './icons'

import styles from './profile-card.module.css'

function ProfileCard ({ profileColor, profileGrey, name, position, team, slug }) {
  return (
    <div className={styles.profileCard} data-teams={team} data-member={name}>
      <Link to={`/our-people/${slug.current}/`}></Link>
      <div className={styles.profileMediaThumb}>
        {profileColor && profileColor.asset && (
          <img
            className={`${styles.colorImage} ${profileGrey && profileGrey.asset && (styles.colorOnly)}`}
            src={imageUrlFor(buildImageObj(profileColor))
              .width(600)
              .height(600)
              .fit('crop')
              .url()}
          />
        )}
        {profileGrey && profileGrey.asset && (
          <img
            className={styles.greyImage}
            src={imageUrlFor(buildImageObj(profileGrey))
              .width(600)
              .height(600)
              .fit('crop')
              .url()}
          />
        )}
      </div>
      <div className={styles.personName}>
        <h5 className={`${styles.headline} ${styles.bullet}`}>
          {name}
        </h5>
        <Icon symbol='chevronright' />
      </div>
      {/* {position && (
        <div className={styles.bio}>
          {position}
        </div>
      )} */}
    </div>
  )
}

export default ProfileCard