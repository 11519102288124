import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import PeopleGrid from '../components/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query PeoplePageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)people/" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
    }

    people: allSanityPerson(sort: {fields: lastName, order: ASC}) {
      edges {
        node {
          id
          profileColor {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
          position
          team
          display
          slug {
            current
          }
        }
      }
    }
  }
`

const PeoplePage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const page = data.page
  const personNodes =
    data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout palette="invert">
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} />

      <Hero palette="invert">
        {page.title}
      </Hero>

      {personNodes && personNodes.length > 0 && <PeopleGrid nodes={personNodes} body={page._rawBody} />} 
    </Layout>
  )
}

export default PeoplePage
