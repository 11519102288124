import React from 'react'
import Container from '../components/container'
import ProfileCard from './profile-card'
import TeamMenu from './team-menu'
import BlockContent from '../components/block-content'

import styles from './people-grid.module.css'

function PeopleGrid (props) {
  const filteredNodes = [];
  props.nodes.map(node => {
    if (node.display) {
      filteredNodes.push(node);
    }
  })
  // const filteredNodes = props.nodes;

  return (
    <div className={styles.root} id="next">
      <Container size="large">
        <div className={styles.titleBar}>
          <div className={styles.subtitle}>
            {props.body && (
              <BlockContent blocks={props.body} />
            )}
          </div>
        </div>
      
        <TeamMenu team={props.nodes} />
      </Container>

      <Container size="large">
        <div className={`${styles.grid}`} id="profiles" data-filtered-teams>
          {filteredNodes &&
              filteredNodes.map(node => (
                <div key={node.id}>
                  <ProfileCard {...node} />
                </div>
              ))}
        </div>

        <div className={`${styles.hidden}`} data-filter-teams>
          {props.nodes &&
            props.nodes.map(node => (
              <div key={node.id}>
                <ProfileCard {...node} />
              </div>
            ))}
        </div>
      </Container>
    </div>
  )
}

PeopleGrid.defaultProps = {
  nodes: [],
}

export default PeopleGrid
